import React from 'react'

import Layout from '../common/layouts'
import Seo from '../common/seo'

export default () => {
  return (
    <Layout>
      <Seo />
      <section className="mw7 center avenir">
        <h2 className="baskerville fw1 ph3 ph0-l">Oops, that's a 404</h2>
      </section>
    </Layout>
  )
}